<template>
  <HelloWorld msg="Welcome"/>
  <label>
    <span class="prefix" v-if="presetURL">{{presetURL}}</span>
    <input v-model="inputStr" placeholder="请输入跳转地址"/>
  </label>
  <div class="btn-grp">
    <button @click="jump">GO</button>
    <button @click="toQRCode">生成二维码</button>
  </div>
  <div class="preset">
  <button class="bili" @click="preset('bilibili')">bilibili</button>
  <button class="ua" @click="goUA">ua</button>
  <button v-if="presetMode" class="back" @click="preset(null)">back</button>
  </div>
  <canvas id="canvas"></canvas>
</template>

<script>
import QRCode from 'qrcode'
import HelloWorld from './components/HelloWorld.vue'
const PRESET = {
  bilibili: 'bilibili://browser?url=<url>',
}
export default {
  name: 'App',
  data() {
    return {
      inputStr: '',
      presetMode: null
    }
  },
  computed: {
    presetURL() {
      return PRESET[this.presetMode] ? PRESET[this.presetMode].replace('<url>', '') : null
    }
  },
  components: {
    HelloWorld
  },
  methods: {
    formatURL() {
      if (!this.inputStr) window.alert('请输入地址')
      let redirect = this.inputStr
      if (this.presetMode) {
        redirect = PRESET[this.presetMode].replace('<url>', encodeURIComponent(this.inputStr))
      }
      return redirect
    },
    toQRCode() {
      const redirect = this.formatURL()
      QRCode.toCanvas(document.getElementById('canvas'), redirect, function (error) {
        if (error) console.error(error)
        console.log('success!');
      })
    },
    jump() {
      const redirect = this.formatURL()
      try {
        location.href = redirect
      } catch(e) {
        window.alert(e)
      }
    },
    preset(str) {
      this.presetMode = str
    },
    goUA() {
      this.inputStr = decodeURIComponent('https%3A%2F%2Fwww.ip138.com%2Fuseragent%2F')
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
* {
  box-sizing: border-box;
}
input {
  height: 30px;
  padding: 6px;
  width: 300px;
  max-width: 100%;
}
button {
  margin-top: 10px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #42b983;
  color: #ffffff;
  outline: none;
  border: none;
  text-align: center;
  min-width: 64px;
  padding: 6px 12px;
}
.btn-grp {
  display: flex;
  justify-content: center;
}
.btn-grp button {
  margin-right: 12px;
}
.prefix {
  font-style: italic;
  opacity: .8;
}
.preset {
  display: flex;
  justify-content: flex-start;
}
.preset button {
  margin-left: 20px;
  margin-right: 0;
  background: #2c3e50;
}
.preset button.back {
  background: none;
  color: #2c3e50;
}
</style>
